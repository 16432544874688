import React, { Component } from "react";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import Layout from "../components/layout";
import ItemListing from "../components/item-listing";
import Card from "../components/card";
import styled from "styled-components";
import HeroArea from "../components/hero-area";
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import FAQ from "../components/FAQ";


const MainContentContainer = styled.div`
  h1 {
    margin-bottom: 0.25em;
  }
`;

const SidebarContainer = styled.div``;
const Spacer = styled.div`
  height: 5px;
`;
const MapTitle = styled.p`
  text-align: center;
  font-size: 12px;
  padding-top: 10px;
  font-weight: 600;
`;

class About extends Component {
  render() {
    const pageData = this.props.data.contentfulPage;

    const showContactForm = this.props.location.pathname.includes("contact");

    const SidebarColumn = () => {
      const sideBarCards = pageData.sidebar.map((sidebar, index) => <Card key={index} sidebar={sidebar} />);

      return sideBarCards;
    };

    return (
      <Layout pageProps={this.props}>
        <SEO
          title={pageData.metaTitle}
          description={pageData.metaDescription}
          keywords={pageData.metaKeywordsList ? pageData.metaKeywordsList : []}
          robots={pageData.metaRobotsContent}
        /> 

        {pageData && pageData.heroArea ? <HeroArea heroContent={pageData && pageData.heroArea} /> : ""}

        <div class="mx-auto max-w-7xl px-6  lg:px-8 py-12 md:py-16">
        <div class="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
          <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">History</h2>
          <div class="mt-6 flex flex-col gap-x-8 gap-y-20 lg:flex-row">
            <div class="lg:w-full lg:max-w-2xl lg:flex-auto">
               <div class="ax-w-xl text-base leading-7 text-gray-700 prose">
              <div dangerouslySetInnerHTML={{ __html: pageData.body.body }} /> 
                  </div>
            </div>
            <div class="lg:flex lg:flex-auto lg:justify-center">
              <dl class="w-64 space-y-8 xl:w-80">
                <div class="flex flex-col-reverse gap-y-4">
                    <dt class="text-base leading-7 text-gray-600">Home improvement projects completed</dt>
                    <dd class="text-5xl font-semibold  font-display  text-brand-700">3000+</dd>
                  </div>
                <div class="flex flex-col-reverse gap-y-4">
                    <dt class="text-base leading-7 text-gray-600">Average rating from customers</dt>
                    <dd class="text-5xl font-semibold  font-display   text-brand-700">4.9/5</dd>
                  </div>
                <div class="flex flex-col-reverse gap-y-4">
                    <dt class="text-base leading-7 text-gray-600">based contractors and specialists</dt>
                    <dd class="text-5xl font-semibold  font-display   text-brand-700">Michigan</dd>
                  </div> 
              </dl>
            </div>
          </div>
        </div>
      </div>

<div className="bg-white shadow-md py-8 md:py-16">
      <div className="mx-auto  max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Our Team</h2>
            <p className="mt-4 text-lg leading-8 text-gray-600">
            They will make your home’s upgrade a smooth process from start to finish.
            </p>
          </div>
          <ul
            role="list"
            className="mx-auto mt-12 grid max-w-2xl grid-cols-2 gap-x-8 gap-y-16 text-center sm:grid-cols-3 md:grid-cols-4 lg:mx-0 lg:max-w-none xl:grid-cols-6"
          >
            {pageData.team.map((person) => (
              <li key={person.title}>
              
                <GatsbyImage
                 className="mx-auto h-40 w-40 rounded-xl top" 
        image={getImage(person.gatsbyImageData)}
        // style={{ minHeight: '100%', margin: '0 auto' }}
        alt={person.title}
      />

                <h3 className="mt-4 text-base font-semibold leading-7 tracking-tight text-gray-900">{person.title}</h3>
                <p className="text-sm leading-6 text-gray-600">{person.description}</p>
              </li>
            ))}
          </ul>
        </div>

        
        <div>
        </div>
 
        </div>
        <FAQ/>
      </Layout>
    );
  }
}

export default About;

export const pageQuery = graphql`
  query aboutPageQuery {
    contentfulPage(slug: {eq: "about/"}) {
      id
      pageTitle
      metaTitle
      metaDescription
      slug
      metaKeywordsList
      metaRobotsContent

    team {

      id
        title
        description
        gatsbyImageData(width: 370, quality: 100)
      }

      itemListing {
        id
        serviceTitle
        excerpt
        slug
        heroImage {
          gatsbyImageData(width: 370, quality: 70)
        }
      }

      heroArea {
        id
        name
        heroTitle
        description {
          description
        }
        heroImage {
          gatsbyImageData(width: 1000, quality: 80)
        }
        primaryActionBtnLabel
        primaryActionBtnLink
      }
      body {
        body
      }
      sidebar {
        id
        title
        content {
          id
          content
        }
        testimonialCategory {
          id
          name
        }
        cssClasses
      }
    }
  }
`;

//  mb-6  font-bold text-2xl md:text-3xl text-base md:text-lg text-neutral-600
